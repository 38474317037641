import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Bloglist from "./pages/Bloglist";
import BlogDetails from "./pages/BlogDetails";
import Portfoliolist from './pages/Portfoliolist';
import WorkDetails from './pages/WorkDetails';
import "./App.scss";
import ScrollIntoView from './components/ScrollIntoView';
import ScrollUpButton from 'react-scroll-up-button';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-57491346-4');
ReactGA.pageview(window.location.pathname + window.location.search);
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <Router history={history}>
    <ScrollIntoView>
      <ScrollUpButton />
      <Switch>
        <Route path="/" exact>
          <Homepage />
        </Route>
        <Route path="/blogs" component={Bloglist} exact/>
        <Route path="/portfolio" component={Portfoliolist} exact/>
        <Route path="/portfolio/:title" component={WorkDetails} />
        <Route path="/blogs/:title" component={BlogDetails} />
      </Switch>
      </ScrollIntoView>
    </Router>
  );
}

export default App;
